import(/* webpackMode: "eager" */ "/home/app/client/pb-front/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/home/app/client/pb-front/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/home/app/client/pb-front/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/home/app/client/pb-front/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/app/client/pb-front/node_modules/react-loading-skeleton/dist/index.js");
;
import(/* webpackMode: "eager" */ "/home/app/client/pb-front/public/assets/faq/faq-auth-1.png");
;
import(/* webpackMode: "eager" */ "/home/app/client/pb-front/public/assets/faq/faq-auth-2.png");
;
import(/* webpackMode: "eager" */ "/home/app/client/pb-front/public/assets/faq/faq-auth-3.png");
;
import(/* webpackMode: "eager" */ "/home/app/client/pb-front/public/assets/faq/faq-purchase-1.png");
;
import(/* webpackMode: "eager" */ "/home/app/client/pb-front/public/assets/faq/faq-purchase-2.png");
;
import(/* webpackMode: "eager" */ "/home/app/client/pb-front/src/entities/card-category/ui/card-category.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["BannerImageEntityDesktop"] */ "/home/app/client/pb-front/src/entities/desktop/banner-image/banner-image.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["CardInventoryEntity"] */ "/home/app/client/pb-front/src/entities/desktop/cards/card-inventory/ui/card-inventory.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["CardItemOfferEntity"] */ "/home/app/client/pb-front/src/entities/desktop/cards/card-item-offer/ui/card-item-offer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["CardItemEntity"] */ "/home/app/client/pb-front/src/entities/desktop/cards/card-item/ui/card-item.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["CardLiveFeedEntityItemInfo"] */ "/home/app/client/pb-front/src/entities/desktop/cards/card-live-feed/ui/item-info.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["CardPriceItemPageEntity"] */ "/home/app/client/pb-front/src/entities/desktop/cards/card-price-item-page/ui/card-price-item-page.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["CardShoppingItemEntity"] */ "/home/app/client/pb-front/src/entities/desktop/cards/card-shopping-item/ui/card-shopping-item.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["CardHistoryEntityMobile"] */ "/home/app/client/pb-front/src/entities/mobile/cards/card-history/ui/card-history.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["CardInventoryEntityMobile"] */ "/home/app/client/pb-front/src/entities/mobile/cards/card-inventory/ui/card-inventory.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["CardItemShopEntityMobile"] */ "/home/app/client/pb-front/src/entities/mobile/cards/card-item-shop/ui/card-item-shop.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["CardShoppingItemEntityMobile"] */ "/home/app/client/pb-front/src/entities/mobile/cards/card-shopping-item/ui/card-shopping-item.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["CartBadgeItemsCounter"] */ "/home/app/client/pb-front/src/features/cart-items-counter/ui/cart-badge-count.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["CloseAdvertisingButton"] */ "/home/app/client/pb-front/src/features/desktop/adverising/close/close.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["LearnMoreButton"] */ "/home/app/client/pb-front/src/features/desktop/adverising/learn-more/learn-more.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["DropListProfile"] */ "/home/app/client/pb-front/src/features/drop-list-profile/drop-list-profile.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["DropListSort"] */ "/home/app/client/pb-front/src/features/drop-list-sort/index.ts");
;
import(/* webpackMode: "eager", webpackExports: ["HeaderTabsFeatureMobile"] */ "/home/app/client/pb-front/src/features/mobile/header-tabs/header-tabs.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["DropListBalanceContent"] */ "/home/app/client/pb-front/src/shared/components/drop-list-balance/drop-list-balance-content.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["DropListBalance"] */ "/home/app/client/pb-front/src/shared/components/drop-list-balance/drop-list-balance.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["InputSearchAutocomplete"] */ "/home/app/client/pb-front/src/shared/components/input-search-autocomplete/ui/input-search-autocomplete.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["WithCurrency"] */ "/home/app/client/pb-front/src/shared/hocs/with-currency.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["WithShopItems"] */ "/home/app/client/pb-front/src/shared/hocs/with-shop-items.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Accordion"] */ "/home/app/client/pb-front/src/shared/ui/accordion/index.ts");
;
import(/* webpackMode: "eager", webpackExports: ["ButtonCode"] */ "/home/app/client/pb-front/src/shared/ui/button-code/button-code.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Button"] */ "/home/app/client/pb-front/src/shared/ui/button/button.tsx");
;
import(/* webpackMode: "eager" */ "/home/app/client/pb-front/src/shared/ui/desktop/breadcrumbs/breadcrumbs.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ModalDesktop"] */ "/home/app/client/pb-front/src/shared/ui/desktop/modal-desktop/modal-desktop.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["IllustrationModal"] */ "/home/app/client/pb-front/src/shared/ui/illustration-modal/illustration-modal.tsx");
;
import(/* webpackMode: "eager" */ "/home/app/client/pb-front/src/shared/ui/item/item-image-by-variant/item-image-by-variant.tsx");
;
import(/* webpackMode: "eager" */ "/home/app/client/pb-front/src/shared/ui/mobile/additional-info/additional-info.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["BottomSheetUi"] */ "/home/app/client/pb-front/src/shared/ui/mobile/bottom-sheet/index.ts");
;
import(/* webpackMode: "eager" */ "/home/app/client/pb-front/src/shared/ui/mobile/input-search/input-search-mobile.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["TagTimer"] */ "/home/app/client/pb-front/src/shared/ui/tag-timer/tag-timer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["CategoriesBreadCrumbsDesktop"] */ "/home/app/client/pb-front/src/widgets/desktop/categories/categories-page/ui/breadcrumbs/breadcrumbs.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["CategoriesContentDesktop"] */ "/home/app/client/pb-front/src/widgets/desktop/categories/categories-page/ui/content/content.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["CategoriesWidget"] */ "/home/app/client/pb-front/src/widgets/desktop/categories/main-page/ui/categories.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Question"] */ "/home/app/client/pb-front/src/widgets/desktop/faq/content/ui/questions/question.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["FooterLinksList"] */ "/home/app/client/pb-front/src/widgets/desktop/footer-links-list/ui/footer-links-list.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["HeaderCategoryButton"] */ "/home/app/client/pb-front/src/widgets/desktop/header/ui/category-button.tsx");
;
import(/* webpackMode: "eager" */ "/home/app/client/pb-front/src/widgets/desktop/header/ui/header-filters.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["NavigationTabText"] */ "/home/app/client/pb-front/src/widgets/desktop/header/ui/navigation-tab-text.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ItemCardWidget"] */ "/home/app/client/pb-front/src/widgets/desktop/item-card/ui/item-card.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ItemsWithdrawalWidget"] */ "/home/app/client/pb-front/src/widgets/desktop/items-withdrawal/ui/items-withdrawal.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["HotSalesItems"] */ "/home/app/client/pb-front/src/widgets/desktop/list-cards-wrappers/hot-sales-items.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["PopularItems"] */ "/home/app/client/pb-front/src/widgets/desktop/list-cards-wrappers/popular-items.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SimilarInPriceItems"] */ "/home/app/client/pb-front/src/widgets/desktop/list-cards-wrappers/similar-in-price-items.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ListCardsWidgetVirtualized"] */ "/home/app/client/pb-front/src/widgets/desktop/list-cards/ui/list-cards-virtualized.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ListCardsWidget"] */ "/home/app/client/pb-front/src/widgets/desktop/list-cards/ui/list-cards.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ShopCardsVirtualized"] */ "/home/app/client/pb-front/src/widgets/desktop/list-cards/ui/shop-cards-virtualized.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["LiveFeedCarousel"] */ "/home/app/client/pb-front/src/widgets/desktop/live-feed/ui/live-feed-carousel.tsx");
;
import(/* webpackMode: "eager" */ "/home/app/client/pb-front/src/widgets/desktop/live-feed/ui/live-feed-sorting-tabs.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["MainPromoBottomBannersCarousel"] */ "/home/app/client/pb-front/src/widgets/desktop/main-promo-banners/main-bottom-carousel.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["MainPromoTopBannersCarousel"] */ "/home/app/client/pb-front/src/widgets/desktop/main-promo-banners/main-top-carousel.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["OfferWidget"] */ "/home/app/client/pb-front/src/widgets/desktop/offer/offer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["PriceCardWidget"] */ "/home/app/client/pb-front/src/widgets/desktop/price-card/price-card.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ProfileHistoryWidget"] */ "/home/app/client/pb-front/src/widgets/desktop/profile-history/ui/profile-history.tsx");
;
import(/* webpackMode: "eager" */ "/home/app/client/pb-front/src/widgets/desktop/profile/content/ui/left-container/blocks/balance.tsx");
;
import(/* webpackMode: "eager" */ "/home/app/client/pb-front/src/widgets/desktop/profile/content/ui/left-container/blocks/logout.tsx");
;
import(/* webpackMode: "eager" */ "/home/app/client/pb-front/src/widgets/desktop/profile/content/ui/left-container/blocks/navigation.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SettingsWidget"] */ "/home/app/client/pb-front/src/widgets/desktop/settings/ui/settings.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["CategoryTitle"] */ "/home/app/client/pb-front/src/widgets/desktop/shop/ui/breadcrumbs/category-title.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ShopCards"] */ "/home/app/client/pb-front/src/widgets/desktop/shop/ui/cards/cards.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ShopSelectedFilters"] */ "/home/app/client/pb-front/src/widgets/desktop/shop/ui/selected-filters/selected-filters.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ShopSorting"] */ "/home/app/client/pb-front/src/widgets/desktop/shop/ui/sorting/sorting.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SidebarWidget"] */ "/home/app/client/pb-front/src/widgets/desktop/sidebar/ui/sidebar.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ToastWidget"] */ "/home/app/client/pb-front/src/widgets/desktop/toast/ui/toast.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["AdvertisingWidgetMobile"] */ "/home/app/client/pb-front/src/widgets/mobile/advertising/ui/advertising.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["CartBottomBarMobile"] */ "/home/app/client/pb-front/src/widgets/mobile/cart/ui/bottom-bar/bottom-bar.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["CartEmptyMobile"] */ "/home/app/client/pb-front/src/widgets/mobile/cart/ui/empty/empty.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["CartHeaderMobile"] */ "/home/app/client/pb-front/src/widgets/mobile/cart/ui/header/header.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["CartItemsMobile"] */ "/home/app/client/pb-front/src/widgets/mobile/cart/ui/items/items.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["CartPurchaseCheckoutMobile"] */ "/home/app/client/pb-front/src/widgets/mobile/cart/ui/purchase-checkout/purchase-checkout.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["CategoriesList"] */ "/home/app/client/pb-front/src/widgets/mobile/categories/categories-page/ui/list/categories-list.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["CategoriesWidgetMobile"] */ "/home/app/client/pb-front/src/widgets/mobile/categories/main-page/ui/categories.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["FAQQuestionItemMobile"] */ "/home/app/client/pb-front/src/widgets/mobile/faq/content/ui/questions/question-item/question-item.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["FavoritesCardsMobile"] */ "/home/app/client/pb-front/src/widgets/mobile/favorites/content/ui/cards/cards.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["FavoritesFiltersMobile"] */ "/home/app/client/pb-front/src/widgets/mobile/favorites/content/ui/filters/filters.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["FooterAccordionsWrapper"] */ "/home/app/client/pb-front/src/widgets/mobile/footer/ui/footer-accordions.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["FooterWidgetMobileLinksList"] */ "/home/app/client/pb-front/src/widgets/mobile/footer/ui/footer-links-list.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["FooterWidgetMobileWrapper"] */ "/home/app/client/pb-front/src/widgets/mobile/footer/ui/footer-wrapper.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["HeaderWidgetMobile"] */ "/home/app/client/pb-front/src/widgets/mobile/header/ui/header.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ItemsWithdrawalWidgetMobile"] */ "/home/app/client/pb-front/src/widgets/mobile/items-withdrawal/ui/items-withdrawal.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["CloseInValueItems","ExclusiveItems","HotSalesItems","PopularItems"] */ "/home/app/client/pb-front/src/widgets/mobile/list-cards-wrappers/list-cards-wrappers.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["LiveFeedWidgetMobile"] */ "/home/app/client/pb-front/src/widgets/mobile/live-feed/ui/live-feed.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["MainPromoBottomBannersCarousel","MainPromoTopBannersCarousel"] */ "/home/app/client/pb-front/src/widgets/mobile/main-promo-banners/main-promo-banners.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["NavigationWidgetMobile"] */ "/home/app/client/pb-front/src/widgets/mobile/navigation/ui/navigation.tsx");
;
import(/* webpackMode: "eager" */ "/home/app/client/pb-front/src/widgets/mobile/profile-history/ui/profile-history.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ProfileSettingsWidgetMobile"] */ "/home/app/client/pb-front/src/widgets/mobile/settings/ui/settings.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Notifications"] */ "/home/app/client/pb-front/src/widgets/notifications/ui/notifications.tsx");
